import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Config from "../config"
import { createGlobalStyle } from "styled-components"
import Loader from "react-loader-spinner"

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

const GlobalStyle = createGlobalStyle`
  body {
    font-family: "Open Sans", sans-serif;
    background: ${props => props.data.primary};
    color: ${props => props.data.color};
  }

  #header {
    background: ${props => props.data.navbar_color};
    border-bottom: 1px solid ${props => props.data.navbar_color};
    transition: all 0.5s;
    z-index: 997;
    padding: 15px 0;
  }

  #header .logo a {
    color: ${props => props.data.secondary};
  }

  .navbar a:hover,
  .navbar .active,
  .navbar li:hover > a {
    color: ${props => props.data.active_color};
  }

  .navbar-mobile a:hover,
  .navbar-mobile .active,
  .navbar-mobile li:hover > a {
    color: ${props => props.data.active_color} !important;
  }

  .navbar a {
    color: ${props => props.data.navbar_font_color};
  }

  .about .about-img::before {
    position: absolute;
    left: 20px;
    top: 20px;
    width: 60px;
    height: 60px;
    z-index: 1;
    content: "";
    border-left: 5px solid ${props => props.data.secondary};
    border-top: 5px solid ${props => props.data.secondary};
    transition: 0.5s;
  }

  .about .about-img::after {
    position: absolute;
    right: 20px;
    bottom: 20px;
    width: 60px;
    height: 60px;
    z-index: 2;
    content: "";
    border-right: 5px solid ${props => props.data.secondary};
    border-bottom: 5px solid ${props => props.data.secondary};
    transition: 0.5s;
  }

  .section-bg {
    background-color: ${props => props.data.ternary};
  }

  .section-bg .section-title p {
    color: ${props => props.data.color_bis};
  }

  section {
    padding: 60px 0;
    overflow: hidden;
  }
  
  .section-title {
    padding-bottom: 40px;
  }
  
  .section-title h2 {
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    line-height: 1px;
    margin: 0 0 5px 0;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: ${props => props.data.secondary};
    font-family: "Poppins", sans-serif;
  }
  
  .section-title h2::after {
    content: "";
    width: 120px;
    height: 1px;
    display: inline-block;
    background: ${props => props.data.secondary};
    margin: 4px 10px;
  }
  
  .section-title h3 {
    margin: 0;
    margin: 0;
    font-size: 36px;
    font-weight: 700;
    font-family: "Playfair Display", serif;
    color: ${props => props.data.secondary};
  }

  a {
    color: ${props => props.data.secondary};
    text-decoration: none;
  }

  #preloader:before {
    content: "";
    position: fixed;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    border: 6px solid #1a1814;
    border-top-color: ${props => props.data.secondary};
    border-bottom-color: ${props => props.data.secondary};
    border-radius: 50%;
    width: 60px;
    height: 60px;
    -webkit-animation: animate-preloader 1s linear infinite;
    animation: animate-preloader 1s linear infinite;
  }

  .back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 996;
    width: 44px;
    height: 44px;
    border-radius: 50px;
    transition: all 0.4s;
    border: 2px solid ${props => props.data.secondary};
  }

  .back-to-top i {
    font-size: 28px;
    color: ${props => props.data.secondary};
    line-height: 0;
  }
  
  .back-to-top:hover {
    background: ${props => props.data.secondary};
    color: #1a1814;
  }

  #topbar .languages ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    color: ${props => props.data.secondary};
  }

  .book-a-table-btn {
    margin: 0 0 0 15px;
    border: 2px solid ${props => props.data.secondary};
    color: ${props => props.data.color_bis};
    border-radius: 50px;
    padding: 8px 25px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1px;
    transition: 0.3s;
  }
  
  .book-a-table-btn:hover {
    background: ${props => props.data.secondary};
    color: ${props => props.data.color_bis};
  }

  .navbar .dropdown ul a:hover,
  .navbar .dropdown ul .active:hover,
  .navbar .dropdown ul li:hover > a {
    color: ${props => props.data.secondary};
  }

  .navbar-mobile .dropdown ul a:hover,
  .navbar-mobile .dropdown ul .active:hover,
  .navbar-mobile .dropdown ul li:hover > a {
    color: ${props => props.data.secondary};
  }

  #hero h1 span {
    color: ${props => props.data.secondary};
  }

  #hero .btn-menu,
  #hero .btn-book {
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    display: inline-block;
    padding: 12px 30px;
    border-radius: 50px;
    transition: 0.3s;
    line-height: 1;
    color: white;
    border: 2px solid ${props => props.data.secondary};
    background: ${props => props.data.secondary};
  }

  #hero .btn-menu:hover,
  #hero .btn-book:hover {
    background: ${props => props.data.secondary};
    color: ${props => props.data.color_bis};
  }

  #hero .play-btn {
    width: 94px;
    height: 94px;
    background: radial-gradient(${props =>
      props.data.secondary} 50%, rgba(205, 164, 94, 0.4) 52%);
    border-radius: 50%;
    display: block;
    position: relative;
    overflow: hidden;
  }

  #hero .play-btn:hover::after {
    border-left: 15px solid ${props => props.data.secondary};
    transform: scale(20);
  }

  .about .content ul i {
    font-size: 20px;
    padding-right: 4px;
    color: ${props => props.data.secondary};
  }

  .why-us .box span {
    display: block;
    font-size: 28px;
    font-weight: 700;
    color: ${props => props.data.secondary};
  }

  .menu #menu-flters li:hover,
  .menu #menu-flters li.filter-active {
    color: ${props => props.data.secondary};
  }
  
  .menu .menu-content span {
    background: ${props => props.data.ternary};
    position: relative;
    z-index: 3;
    padding: 0 10px;
    font-weight: 600;
    color: ${props => props.data.secondary};
  }

  .menu .menu-content .title {
    padding-right: 10px;
    background: ${props => props.data.ternary};
    position: relative;
    z-index: 3;
    font-weight: 700;
    color: ${props => props.data.secondary};;
    transition: 0.3s;
  }

  .menu .menu-ingredients {
    margin-left: 85px;
    font-style: italic;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    color:  ${props => props.data.color_bis};
  }

  .specials .nav-link {
    border: 0;
    padding: 12px 15px;
    transition: 0.3s;
    color: ${props => props.data.color_bis};
    border-radius: 0;
    border-right: 2px solid ${props => props.data.secondary};
    font-weight: 600;
    font-size: 15px;
  }
  
  .specials .nav-link:hover {
    color: ${props => props.data.secondary};
  }
  
  .specials .nav-link.active {
    color: #1a1814;
    background: ${props => props.data.secondary};
    border-color: ${props => props.data.secondary};
  }
  
  .specials .nav-link:hover {
    border-color: ${props => props.data.secondary};
  }

  .events .event-item h3 {
    font-weight: 600;
    font-size: 26px;
    color: ${props => props.data.secondary};
  }

  .events .event-item .price span {
    border-bottom: 2px solid ${props => props.data.secondary};
  }

  .events .event-item ul i {
    font-size: 20px;
    padding-right: 4px;
    color: ${props => props.data.secondary};
  }

  .events .swiper-pagination .swiper-pagination-bullet-active {
    background-color: ${props => props.data.secondary};
  }

  .book-a-table .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid ${props => props.data.secondary};
    border-top-color: #1a1814;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
  }

  .book-a-table .php-email-form input:focus,
  .book-a-table .php-email-form textarea:focus {
    border-color: ${props => props.data.secondary};
  }

  .book-a-table .php-email-form button[type="submit"] {
    background: ${props => props.data.secondary};
    border: 0;
    padding: 10px 35px;
    color: ${props => props.data.color_bis};
    transition: 0.4s;
    border-radius: 50px;
  }

  .testimonials .swiper-pagination .swiper-pagination-bullet-active {
    background-color: ${props => props.data.secondary};
  }

  .chefs .member .social a:hover {
    color: ${props => props.data.secondary};
  }

  .contact .info i {
    font-size: 20px;
    float: left;
    width: 44px;
    height: 44px;
    background: ${props => props.data.secondary};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
  }

  .contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid ${props => props.data.secondary};
    border-top-color: #1a1814;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
  }

  .contact .php-email-form input:focus,
  .contact .php-email-form textarea:focus {
    border-color: ${props => props.data.secondary};
    background: #fff;
  }

  .contact .php-email-form button[type="submit"] {
    background: ${props => props.data.secondary};
    border: 0;
    padding: 10px 35px;
    color: ${props => props.data.color_bis};
    transition: 0.4s;
    border-radius: 50px;
  }

  #footer .footer-top .social-links a:hover {
    background: ${props => props.data.secondary};
    color: ${props => props.data.color_bis};
    text-decoration: none;
  }

  #footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: ${props => props.data.secondary};
    font-size: 18px;
    line-height: 1;
  }

  #footer .footer-top .footer-links ul a:hover {
    color: ${props => props.data.secondary};
  }

  #footer .footer-top .footer-newsletter form input[type="submit"] {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px 2px 20px;
    background: ${props => props.data.secondary};
    color: ${props => props.data.color_bis};
    transition: 0.3s;
    border-radius: 50px;
  }

  #topbar .contact-info i {
    font-style: normal;
    color: ${props => props.data.secondary};
  }

  a:hover {
    color: ${props => props.data.secondary};
    text-decoration: none;
  }

  .book-a-table .php-email-form button[type="submit"]:hover {
    background: ${props => props.data.secondary};
  }

  .testimonials .testimonial-item .quote-icon-left,
  .testimonials .testimonial-item .quote-icon-right {
    color: ${props => props.data.secondary};
    font-size: 26px;
  }

  .contact .php-email-form button[type="submit"]:hover {
    background: ${props => props.data.secondary};
  }

  #footer .footer-top .footer-newsletter form input[type="submit"]:hover {
    background: ${props => props.data.secondary};
  }

  .contact .php-email-form input,
  .contact .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
    background: #fff;
    border-color: ${props => props.data.secondary};
    color: ${props => props.data.secondary};
  }

  .contact .php-email-form input::-webkit-input-placeholder,
  .contact .php-email-form textarea::-webkit-input-placeholder {
    color: #a49b89;
  }

  .contact .php-email-form input::-moz-placeholder,
  .contact .php-email-form textarea::-moz-placeholder {
    color: #a49b89;
  }

  .contact .php-email-form input::placeholder,
  .contact .php-email-form textarea::placeholder {
    color: #a49b89;
  }

  .why-us .box {
    padding: 50px 30px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    transition: all ease-in-out 0.3s;
    background: ${props => props.data.primary};
  }
  
  .why-us .box h4 {
    font-size: 24px;
    font-weight: 600;
    padding: 0;
    margin: 20px 0;
    color: ${props => props.data.color};
  }
  
  .why-us .box p {
    color: ${props => props.data.color};
    font-size: 15px;
    margin: 0;
    padding: 0;
  }

  .blog-container{
    border: 1px solid #ddd;
  }

  .blog-box span {
    display: block;
    font-size: 28px;
    font-weight: 700;
    color: ${props => props.data.secondary};
  }

  .blog-box {
    padding: 50px 30px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    transition: all ease-in-out 0.3s;
    background: ${props => props.data.primary};
  }
  
  .blog-box h5 {
    font-size: 24px;
    font-weight: 600;
    padding: 0;
    margin: 20px 0;
    color: ${props => props.data.color};
  }

  .blog-box h5:hover {
    font-size: 24px;
    font-weight: 600;
    padding: 0;
    margin: 20px 0;
    color: ${props => props.data.secondary};
  }
  
  .blog-box p {
    color: ${props => props.data.color};
    font-size: 15px;
    margin: 0;
    padding: 0;
  }

  #topbar .contact-info i span {
    padding-left: 5px;
    color: ${props => props.data.color_bis};
  }
  
  #topbar .languages ul a {
    color: ${props => props.data.color_bis};
  }

  .navbar a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 10px 30px;
    color: ${props => props.data.navbar_font_color};
    white-space: nowrap;
    transition: 0.3s;
    font-size: 14px;
  }

  #hero h1 {
    margin: 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    color: ${props => props.data.title_color};
    font-family: "Poppins", sans-serif;
  }
  
  #hero h2 {
    color: ${props => props.data.title_color};
    margin-bottom: 10px 0 0 0;
    font-size: 22px;
  }

  #hero .play-btn::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid ${props => props.data.color_bis};
    z-index: 100;
    transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  #hero .play-btn:hover::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border: none;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid ${props => props.data.color_bis};
    z-index: 200;
    -webkit-animation: none;
    animation: none;
    border-radius: 0;
  }

  .menu #menu-flters li {
    cursor: pointer;
    display: inline-block;
    padding: 8px 12px 10px 12px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    color: ${props => props.data.color_bis};
    margin-bottom: 10px;
    transition: all ease-in-out 0.3s;
    border-radius: 50px;
    font-family: "Playfair Display", serif;
  }

  .specials .details h3 {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 20px;
    color: ${props => props.data.color_bis};
  }
  
  .events .section-title h2 {
    color: ${props => props.data.color_bis};
  }

  .events .event-item {
    color: ${props => props.data.color_bis};
  }

  .chefs .member .member-info-content h4 {
    font-weight: 700;
    margin-bottom: 2px;
    font-size: 18px;
    color: ${props => props.data.color_bis};
  }

  .chefs .member .member-info-content span {
    font-style: italic;
    display: block;
    font-size: 13px;
    color: ${props => props.data.color_bis};
  }

  .chefs .member .social a {
    transition: color 0.3s;
    color: ${props => props.data.color_bis};
    margin: 0 10px;
    display: inline-block;
  }

  #footer {
    color: ${props => props.data.footer_font_color};
    font-size: 14px;
  }

  #footer .footer-top .footer-newsletter form {
    margin-top: 30px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border-radius: 50px;
    border: 1px solid ${props => props.data.footer_color};
  }

  #footer .footer-top .footer-newsletter form input[type="email"] {
    border: 0;
    padding: 4px;
    width: calc(100% - 110px);
    background: #fff;
    color: ${props => props.data.color};
  }

  #footer .footer-top {
    background:  ${props => props.data.footer_color};
    opacity: 1;
    border-top: 1px solid #37332a;
    border-bottom: 1px solid #28251f;
    padding: 60px 0 30px 0;
  }

  #footer .footer-top .footer-info h3 {
    font-size: 24px;
    margin: 0 0 20px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 300;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    color: ${props => props.data.footer_font_color};
  }

  #footer .footer-top .footer-info p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: "Playfair Display", serif;
    color: ${props => props.data.footer_font_color};
  }
  
  #footer .footer-top .social-links a {
    font-size: 18px;
    display: inline-block;
    background:  ${props => props.data.footer_color};
    ccolor: ${props => props.data.footer_font_color};
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
  }

  #footer .footer-top h4 {
    font-size: 16px;
    font-weight: 600;
    color: ${props => props.data.footer_font_color};
    position: relative;
    padding-bottom: 12px;
  }

  #footer .footer-top .footer-links ul a {
    color: ${props => props.data.footer_font_color};
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
  }

  #footer .credits {
    padding-top: 10px;
    text-align: center;
    font-size: 13px;
    color: ${props => props.data.footer_font_color};
  }

  .footer-bottom {
    background:  ${props => props.data.footer_color};
    opacity: 0.9;
    padding: 0 0 10px 0;
  }

  .blockquote {
    font-family: Georgia, serif;
    font-size: 16px;
    font-style: italic;
    margin: 0.25em 0;
    padding: 0.35em 48px;
    line-height: 1.45;
    position: relative;
    color: #383838;
    border-left: 8px solid ${props => props.data.secondary};
  }

  .blockquote:before {
    display: block;
    padding-left: 10px;
    content: '\\201C';
    font-size: 80px;
    position: absolute;
    left: -8px;
    top: -20px;
    color: ${props => props.data.secondary};
  }

  .blockquote cite {
    color: #999999;
    font-size: 12px;
    display: block;
    margin-top: 5px;
  }

  .price-container {
    margin-top: 32px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: ${props => props.data.secondary};
  }

  label.show-more:hover {
    color: ${props => props.data.secondary};
  }
`
/*
  .why-us .box:hover {
    background: ${props => props.data.secondary};
    padding: 30px 30px 70px 30px;
    box-shadow: 10px 15px 30px rgba(0, 0, 0, 0.18);
  }
  .why-us .box:hover span,
  .why-us .box:hover h4,
  .why-us .box:hover p {
    color: ${props => props.data.color_bis};
  }
*/

const Container = ({ children }) => {
  const [settings, setSettings] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  const { allDirectusSetting: setup } = useStaticQuery(
    graphql`
      query {
        allDirectusSetting {
          nodes {
            secondary
          }
        }
      }
    `
  )

  const getData = async () => {
    const call = await fetch(
      `${Config.apiUrl}/items/settings?access_token=${Config.token}&fields=*.*.*`
    )
    const response = await call.json()
    setSettings(response.data[0])
    setIsLoading(false)
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      {isLoading ? (
        <div className="loading-container">
          <div className="loading">
            <Loader
              type="ThreeDots"
              color={setup.nodes[0].secondary}
              height={80}
              width={80}
            />
          </div>
        </div>
      ) : (
        <>
          <GlobalStyle data={settings} />
          {children}
        </>
      )}
    </>
  )
}

export default Container

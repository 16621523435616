import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

const INTERNE = "interne"
const EXTERNE = "externe"
const DROPDOWN = "dropdown"

const NavItem = ({ item }) => {
  return (
    <li key={item.name}>
      <Link
        className="nav-link"
        activeClassName="active"
        to={item.link === null ? `/` : `/${item.link?.slug}`}
      >
        {item.name}
      </Link>
    </li>
  )
}

const NavExternal = ({ item }) => {
  return (
    <li key={item.name}>
      <a
        href={item.external_link}
        className="img-fluid nav-link logo"
        style={{ marginLeft: "30px" }}
        target={item.blank ? "_blank" : "_self"}
        rel="noreferrer"
      >
        {item.icon && (
          <img
            src={item.icon?.data.full_url}
            alt=""
            style={{ maxHeight: "30px" }}
          />
        )}
        {item.label && (
          <span
            className="nav-link"
            style={{ fontSize: "14px", padding: "0 5px" }}
          >
            {item.label}
          </span>
        )}
      </a>
    </li>
  )
}

const NavDropdown = ({ item, mobileNav }) => {
  const [mobileDropdown, setMobileDropdown] = useState("")

  const toggleDropdown = e => {
    e.preventDefault()
    if (mobileNav === "navbar-mobile") {
      setMobileDropdown(mobileDropdown === "" ? "dropdown-active" : "")
    }
  }

  return (
    <li className="dropdown" key={item.name}>
      <a href="/#" role="button" onClick={toggleDropdown}>
        <span>{item.name}</span> <i className="bi bi-chevron-down"></i>
      </a>
      <ul className={`${mobileDropdown}`}>
        {item.list.map(data => (
          <li key={data.label}>
            <Link to={data.link === null ? `/` : `/${data.link?.slug}`}>
              {data.label}
            </Link>
          </li>
        ))}
      </ul>
    </li>
  )
}

const Navbar = () => {
  const [menuIcon, setMenuIcon] = useState("bi-list")
  const [mobileNav, setMobileNav] = useState("")

  const { allDirectusMenu: menu, directusSetting: setting } = useStaticQuery(
    graphql`
      query {
        allDirectusMenu(
          sort: { fields: sort }
          filter: { status: { eq: "published" } }
        ) {
          nodes {
            name
            link {
              slug
            }
            type
            label
            external_link
            blank
            icon {
              data {
                full_url
              }
            }
            list {
              label
              link {
                slug
              }
            }
          }
        }
        directusSetting {
          logo {
            data {
              full_url
            }
          }
        }
      }
    `
  )

  const toggleMenu = () => {
    setMenuIcon(menuIcon === "bi-list" ? "bi-x" : "bi-list")
    setMobileNav(mobileNav === "" ? "navbar-mobile" : "")
  }

  const renderGroup = (item, i) => {
    switch (item.type) {
      case INTERNE:
        return <NavItem key={i} item={item} />
      case EXTERNE:
        return <NavExternal key={i} item={item} />
      case DROPDOWN:
        return <NavDropdown key={i} item={item} mobileNav={mobileNav} />
      default:
        return null
    }
  }

  return (
    <>
      <header id="header" className="fixed-top d-flex align-items-cente">
        <div className="container-fluid container-xl d-flex align-items-center justify-content-lg-between">
          <h1 className="logo me-auto me-lg-0">
            <Link to="/">
              <img
                src={setting.logo?.data.full_url}
                alt=""
                className="img-fluid"
              />
            </Link>
          </h1>

          <nav id="navbar" className={`navbar ${mobileNav}`}>
            <ul>
              {menu.nodes.length &&
                menu.nodes.map((item, i) => renderGroup(item, i))}
            </ul>
            <i
              role="button"
              tabIndex={0}
              aria-label="mobile nav"
              onClick={toggleMenu}
              onKeyDown={toggleMenu}
              className={`bi ${menuIcon} mobile-nav-toggle`}
            ></i>
          </nav>
        </div>
      </header>
    </>
  )
}

export default Navbar

import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Config from "../config"

const Footer = () => {
  const [email, setEmail] = useState("")
  const [success, setSuccess] = useState("none")
  const [error, setError] = useState("none")
  const year = new Date().getFullYear()

  const { allDirectusMenu: menu, directusSetting: setting } = useStaticQuery(
    graphql`
      query {
        allDirectusMenu(
          filter: { type: { eq: "interne" }, status: { eq: "published" } }
          sort: { fields: sort }
        ) {
          nodes {
            name
            link {
              slug
            }
          }
        }
        directusSetting {
          zip
          tva
          phone
          mail
          city
          address
          newsletter
          facebook
          twitter
          instagram
          skype
          linkedin
          cgv {
            data {
              full_url
            }
          }
          use {
            data {
              full_url
            }
          }
          rgpd {
            data {
              full_url
            }
          }
          logo_footer {
            data {
              full_url
            }
          }
        }
      }
    `
  )

  const postNewsletter = async e => {
    e.preventDefault()

    const response = await fetch(
      `${Config.apiUrl}/items/newsletter?access_token=${Config.token}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mail: email,
        }),
      }
    )

    if (response.ok) {
      setSuccess("block")
    } else {
      setError("block")
    }

    setEmail("")
  }

  return (
    <>
      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="footer-info">
                  <h3>
                    <Link to="/">
                      <img
                        src={setting.logo_footer?.data.full_url}
                        alt=""
                        className="img-fluid"
                        style={{ maxHeight: "100px" }}
                      />
                    </Link>
                  </h3>
                  <p>
                    {setting.address} <br />
                    {setting.zip} {setting.city}
                    <br />
                    <br />
                    <strong>Téléphone:</strong> {setting.phone}
                    <br />
                    <strong>Email:</strong> {setting.mail}
                    <br />
                  </p>
                  <div className="social-links mt-3">
                    {setting.twitter && (
                      <a
                        href={setting.twitter}
                        className="twitter"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="bx bxl-twitter"></i>
                      </a>
                    )}
                    {setting.facebook && (
                      <a
                        href={setting.facebook}
                        className="facebook"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="bx bxl-facebook"></i>
                      </a>
                    )}
                    {setting.instagram && (
                      <a
                        href={setting.instagram}
                        className="instagram"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="bx bxl-instagram"></i>
                      </a>
                    )}
                    {setting.skype && (
                      <a
                        href={setting.skype}
                        className="google-plus"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="bx bxl-skype"></i>
                      </a>
                    )}
                    {setting.linkedin && (
                      <a
                        href={setting.linkedin}
                        className="linkedin"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="bx bxl-linkedin"></i>
                      </a>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-6 footer-links">
                <h4>Navigation</h4>
                <ul>
                  {menu.nodes.length &&
                    menu.nodes.map(item => (
                      <li key={item.name}>
                        <i className="bx bx-chevron-right"></i>{" "}
                        <Link
                          to={item.link === null ? `/` : `/${item.link?.slug}`}
                        >
                          {item.name}
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Mentions Légales</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a
                      href={setting.use?.data.full_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Conditions d'utilisation
                    </a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a
                      href={setting.rgpd?.data.full_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Politique de confidentialité
                    </a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a
                      href={setting.cgv?.data.full_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Conditions générales
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-4 col-md-6 footer-newsletter">
                <h4>Notre newsletter</h4>
                <p>{setting.newsletter}</p>
                <form onSubmit={postNewsletter}>
                  <input
                    type="email"
                    name="email"
                    required
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                  <input type="submit" value="Souscrire" />
                </form>
                <div className="error-message" style={{ display: error }}>
                  Une erreur s'est produite, désolé pour le désagrément.
                </div>
                <div className="sent-message" style={{ display: success }}>
                  Votre adresse email a bien été enregistrée.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid footer-bottom">
          <div className="row footer-no-padding">
            <div className="col-lg-3 copyright">
              &copy; {year} - Couleurs et harmonie
            </div>
            <div className="col-lg-4 offset-lg-5 credits">
              site élaboré par{" "}
              <a
                href="https://lifeconsultace.com"
                target="_blank"
                rel="noreferrer"
              >
                Life consultance
              </a>{" "}
              en collaboration avec{" "}
              <a
                href="https://www.websait.com/"
                target="_blank"
                rel="noreferrer"
              >
                Websait
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
